<template>
  <v-container>
    <admin-header :title="'Evaluation'" :links="bcLinks"></admin-header>
    <v-btn :to="from" small text class="ma-0 mb-4 pa-0">
      <v-icon small color="primary" class="mr-2">mdi-arrow-left</v-icon>
      <auth-link-button small title="Back to Trainees"></auth-link-button>
    </v-btn> 
    <div v-if="!pageLoading" class="ma-0 pa-0 mb-12">
      <v-row no-gutters class="mr-12" v-for="(criteria, index) in criterias" :key="index">
        <v-col lg="12" sm="12" cols="12" class="ma-0 pa-0">
          <v-card class="pt-4 mb-3 card-shadow" width="430" v-if="criteria.ratingType === 'NUMBER'">
            <v-row no-gutters class="d-flex flex-row">
              <v-col class="d-flex flex-column" lg="8">
                <v-card-title class="caption textOrange--text my-0 py-0">1 - 10</v-card-title>
                <v-card-text
                  class="my-0 py-0 secondaryText--text font-weight-light caption"
                >{{ criteria.description }}</v-card-text>
                <v-text-field
                  class="pa-0 ma-0 pl-4 pt-2"
                  label="Comment"
                  dense
                  color="secondaryText"
                  v-model="evaluatorCriteria[index].comment"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex align-start justify-center">
                <v-card color="secondaryDark" class="d-flex align-center ma-0 pa-0">
                  <v-btn text small @click="dec(index)" class="ma-0 pa-0" icon>
                    <v-icon small color="textOrange">mdi-minus</v-icon>
                  </v-btn>
                  <v-card
                    flat
                    class="title secondaryText pa-2 ma-4 d-flex align-center justify-center"
                    height="30"
                    width="30"
                  >{{ evaluatorCriteria[index].value }}</v-card>
                  <v-btn text small @click="inc(index)" class="ma-0 pa-0" icon>
                    <v-icon small color="textOrange">mdi-plus</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-card
            width="520"
            class="pt-4 mb-3 card-shadow"
            v-if="criteria.ratingType === 'BOOLEAN'"
          >
            <v-row no-gutters>
              <v-col class="d-flex flex-column" lg="7">
                <v-card-title class="caption textOrange--text my-0 py-0">Yes / No</v-card-title>
                <v-card-text
                  class="my-0 py-0 caption secondaryText--text font-weight-light"
                >{{ criteria.description }}</v-card-text>
                <v-text-field
                  class="pa-0 ma-0 pl-4 pt-2"
                  label="Comment"
                  dense
                  color="secondaryText"
                  v-model="evaluatorCriteria[index].comment"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex flex-row align-start justify-center">
                <v-radio-group row v-model="evaluatorCriteria[index].value" class="d-flex flex-row">
                  <v-card
                    width="90"
                    flat
                    color="secondaryDark"
                    class="mr-2 pa-4 mb-2 d-flex justify-center align-center"
                  >
                    <v-radio
                      class="title font-weight-bold"
                      dense
                      color="textOrange"
                      label="Yes"
                      :value="1"
                    ></v-radio>
                  </v-card>
                  <v-card
                    width="90"
                    flat
                    color="secondaryDark"
                    class="pa-4 d-flex mb-2 justify-center align-center"
                  >
                    <v-radio
                      class="title font-weight-bold"
                      dense
                      color="textOrange"
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-card>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-container
      v-else
      fluid
      class="d-flex justify-center align-center elevation-loading-container"
    >
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-container>
    <v-footer height="65" absolute class="d-flex justify-center">
      <div class="d-flex flex-row">
        <p class="body-1 textDark--text mr-12 my-2">Click on Save Button to Save changes</p>
        <BzButton
          title="Save Changes"
          depressed
          icon="mdi-content-save"
          type="Submit"
          :loading="loading"
          width="240"
          @click="submitEvaluation()"
        >
          <v-divider vertical color="white" class="ml-4 mr-6" />
        </BzButton>
      </div>
    </v-footer>
    <bz-notification v-model="notification"></bz-notification>
  </v-container>
</template>
<style scoped></style>

<script>
import AuthLinkButton from "@/auth/components/AuthLinkButton";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import { mapFields } from "vuex-map-fields";
import BzButton from "@/shared/components/BzSubmitButton";
import { mapActions } from "vuex";
import BzNotification from "@/shared/components/BzNotification";

export default {
  name: "evaluation",
  components: {
    AdminHeader,
    AuthLinkButton,
    BzButton,
    BzNotification
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.from = from;
    });
  },
  data() {
    return {
      from: null,
      criterias: [],
      courseSubmissionId: null,
      loading: false,
      pageLoading: false,
      evaluatorCriteria: [],
      new: true
    };
  },
  async created() {
    if (this.courseCriteria.length == 0) {
      this.$router.go(-1);
    }
    this.courseSubmissionId = this.$route.query.i;
    this.pageLoading = true;
    this.setCriteria();
    await this.fetchEvaluation();
    this.pageLoading = false;
  },
  methods: {
    ...mapActions("evaluator", {
      createEvaluation: "createCourseSubmissionEvaluations",
      patchEvaluation: "patchCourseSubmissionEvaluations",
      getEvaluation: "getCourseSubmissionEvaluations"
    }),
    dec(index) {
      if (this.evaluatorCriteria[index].value > 1) {
        this.evaluatorCriteria[index].value -= 1;
      }
    },
    async fetchEvaluation() {
      var evaluation = await this.getEvaluation({
        courseSubmissionId: this.courseSubmissionId
      });

      if (evaluation && evaluation.length > 0) {
        this.new = false;
        this.setEvaluatorCriteria(evaluation);
      } else {
        this.setEvaluatorCriteria(evaluation);
      }
    },
    inc(index) {
      if (this.evaluatorCriteria[index].value < 10) {
        this.evaluatorCriteria[index].value += 1;
      }
    },
    setCriteria() {
      this.courseCriteria.map(criteria => {
        this.criterias.push({
          _id: criteria._id,
          courseId: criteria.courseId,
          description: criteria.description,
          ratingType: criteria.ratingType
        });
      });
    },
    setEvaluatorCriteria(evaluation) {
      let evaluatorCriteriaArray = [];
      let criteriaObject = {
        _id: null,
        courseSubmissionId: null,
        criteriaId: null,
        comment: "",
        value: 0
      };
      if (evaluation) {
        for (var i in this.criterias) {
          criteriaObject._id =
            evaluation[i] !== undefined ? evaluation[i]._id : null;
          criteriaObject.courseSubmissionId = this.courseSubmissionId;
          criteriaObject.criteriaId = this.criterias[i]._id;
          criteriaObject.comment =
            evaluation[i] !== undefined ? evaluation[i].comment : "";
          criteriaObject.value =
            evaluation[i] !== undefined ? evaluation[i].value : 0;
          evaluatorCriteriaArray.push(criteriaObject);
          criteriaObject = {
            _id: null,
            courseSubmissionId: null,
            criteriaId: null,
            comment: "",
            value: 0
          };
        }
        this.evaluatorCriteria = evaluatorCriteriaArray;
      } else {
        for (var i in this.criterias) {
          criteriaObject._id = null;
          criteriaObject.courseSubmissionId = this.courseSubmissionId;
          criteriaObject.criteriaId = this.criterias[i]._id;
          criteriaObject.comment = "";
          criteriaObject.value = 0;
          evaluatorCriteriaArray.push(criteriaObject);
          criteriaObject = {
            _id: null,
            courseSubmissionId: null,
            criteriaId: null,
            comment: "",
            value: 0
          };
        }
        this.evaluatorCriteria = evaluatorCriteriaArray;
      }
    },
    async submitEvaluation() {
      this.loading = true;
      let response = null;
      try {
        for (var i in this.evaluatorCriteria) {
          if (this.new) {
            response = await this.createEvaluation({
              courseSubmissionId: this.evaluatorCriteria[i].courseSubmissionId,
              criteriaId: this.evaluatorCriteria[i].criteriaId,
              comment: this.evaluatorCriteria[i].comment,
              value: this.evaluatorCriteria[i].value
            });
            this.evaluatorCriteria[i]._id = response._id;
          } else {
            await this.patchEvaluation({
              evaluationId: this.evaluatorCriteria[i]._id,
              comment: this.evaluatorCriteria[i].comment,
              value: this.evaluatorCriteria[i].value
            });
          }
        }
        this.new = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.notify("success", "Your changes have been saved");
      }
    }
  },
  computed: {
    ...mapFields("evaluator", ["courseCriteria"]),

    bcLinks() {
      return [
        {
          text: "Elevation Module",
          disabled: true
        },
        {
          text: "Trainee",
          disabled: true
        },
        {
          text: "Evaluate",
          disabled: false
        }
      ];
    }
  }
};
</script>
